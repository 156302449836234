@tailwind base;

@tailwind components;

@tailwind utilities;

@tailwind screens;

@import 'https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900';
@import 'https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200;400;700&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300&display=swap';

@import './_utils';
@import './_timeline';
@import './_skillbars';
@import './_button';
@import './_skills';
@import './_portfolio';
@import './_menu';

body {
	font: 400 14px/1.8 'Poppins', sans-serif;
	color: #fff;
	overflow-x: hidden;
	background-color: #1f1f20;
}

h1, h2, h3, h4, h5, h6 {
	font-family: 'Josefin Sans', sans-serif;
}

:root {
	--recruiter-mode-display: none;
}

.recruiter-mode {
	display: var(--recruiter-mode-display, none);
}