/* -------------------------------------------------------------------
 * ## timeline
 * ------------------------------------------------------------------- */
 .about-experience__timeline {
    margin-top: 1.6rem;
  }
  
  .about-experience__timeline .column:first-child {
    padding-right: 40px;
  }
  
  .about-experience__timeline .column:last-child {
    padding-left: 40px;
  }
  
  .timeline {
    padding-top: 4.8rem;
    position: relative;
  }
  
  .timeline::before {
    content: "";
    display: block;
    width: 1px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.12);
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .timeline::after {
    content: "";
    display: block;
    width: 5.6rem;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.12);
    position: absolute;
    left: 1px;
    top: 0;
  }
  
  .timeline__icon-wrap {
    display: block;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.25);
    position: absolute;
    top: -2.2rem;
    left: 5.6rem;
  }
  
  .timeline__icon {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
  }
  
  /* // .timeline__icon--work {
  //   background-image: url(../images/icons/icon-briefcase.svg);
  // }
  
  // .timeline__icon--education {
  //   background-image: url(../images/icons/icon-education.svg);
  // } */
  
  .timeline__block {
    position: relative;
    padding-left: 5.6rem;
    padding-bottom: .8rem;
    margin-top: 2.8rem;
    min-height: 25rem;
  }
  
  .timeline .timeline__block:last-child {
    padding-bottom: 0;
  }
  
  .timeline__bullet {
    content: "";
    display: block;
    width: 3px;
    height: 4.8rem;
    background-color: #FFF;
    position: absolute;
    left: -1px;
    top: 8px;
  }
  
  .timeline__header {
    margin-bottom: 3.2rem;
  }
  
  .timeline__header .timeline__timeframe,
  .timeline__header h5 {
    font-family: "Roboto", sans-serif;
  }
  
  .timeline__header .timeline__timeframe {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.714;
    text-transform: uppercase;
    letter-spacing: .3rem;
    margin-bottom: .8rem;
    color: #FFF;
  }
  
  .timeline__header h3 {
    margin-bottom: 0;
  }
  
  .timeline__header h5 {
    font-size: 2.1rem;
    font-weight: 400;
    line-height: 1.333;
    margin-top: 0;
  }
  
  /* ------------------------------------------------------------------- 
   * responsive:
   * about
   * ------------------------------------------------------------------- */
  @media screen and (max-width:1200px) {
    .s-about {
      padding-top: 17.2rem;
      padding-bottom: 15.6rem;
    }
  
    .about-experience__timeline .column:first-child {
      padding-right: 30px;
    }
  
    .about-experience__timeline .column::last-child {
      padding-left: 30px;
    }
  
    .timeline::after {
      width: 4.4rem;
    }
  
    .timeline__icon-wrap {
      left: 4.4rem;
    }
  
    .timeline__block {
      padding-left: 4.4rem;
    }
  
    .timeline__header h5 {
      font-size: 1.8rem;
    }
  
  }
  
  @media screen and (max-width:1024px) {
    .about-me__text {
      column-gap: 40px;
    }
  
    .about-me__buttons .column:first-child {
      padding-right: 20px;
    }
  
    .about-me__buttons .column:last-child {
      padding-left: 20px;
    }
  
    .about-experience__timeline .column:first-child {
      padding-right: 16px;
    }
  
    .about-experience__timeline .column:last-child {
      padding-left: 16px;
    }
  
  }
  
  @media screen and (max-width:900px) {
    .s-about .row {
      max-width: 700px;
    }
  
    .about-me__buttons .column:first-child {
      padding-right: 16px;
    }
  
    .about-me__buttons .column:last-child {
      padding-left: 16px;
    }
  
    .about-experience__timeline .column {
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  
    .about-experience__timeline .column:first-child {
      margin-bottom: 9.6rem;
    }
  
    .timeline::after {
      width: 5.6rem;
    }
  
    .timeline__icon-wrap {
      left: 5.6rem;
    }
  
    .timeline__block {
      min-height: 0;
      padding-left: 5.6rem;
    }
  
  }
  
  @media screen and (max-width:800px) {
    .timeline__header .timeline__timeframe {
      font-size: 0.8rem
    }
    
    .timeline__header h5 {
      font-size: 1.2rem;
    }

    .s-about {
      padding-top: 16rem;
    }
  
    .s-about .row {
      max-width: 600px;
    }
  
  }
  
  @media screen and (max-width:600px) {
    .about-me__buttons .column:first-child,
    .about-experience__timeline .column:first-child {
      padding-right: 10px;
    }
  
    .about-me__buttons .column:last-child,
    .about-experience__timeline .column:last-child {
      padding-left: 10px;
    }
  
    .timeline::after {
      width: 4.4rem;
    }
  
    .timeline__icon-wrap {
      left: 4.4rem;
    }
  
    .timeline__block {
      padding-left: 2rem;
    }
  
    .timeline__header h5 {
      font-size: 1.8rem;
    }
  
  }
  
  @media screen and (max-width:400px) {
    .s-about > [class*="about-"] {
      margin-bottom: 14rem;
    }
  
    .about-me__buttons .column:first-child,
    .about-experience__timeline .column:first-child {
      padding-right: 0;
    }
  
    .about-me__buttons .column:last-child,
    .about-experience__timeline .column:last-child {
      padding-left: 0;
    }
  
    .timeline::after {
      width: 2.8rem;
    }
  
    .timeline__icon-wrap {
      left: 2.8rem;
    }
  
    .timeline__block {
      padding-left: 2.8rem;
    }
  
  }