
/*** Custom button style ***/

.btn {
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 13px;
	padding: 8px 26px;
	border: 0;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active {
	outline: inherit !important;
}

.btn-list .btn {
	margin-bottom: 5px;
}

.btn-custom-1,
.btn-custom-2,
.btn-custom-3,
.btn-custom-4 {
	padding: 7px 25px;
}

.btn-custom-1 {
	background: #49e2d6;
	border: 1px solid #49e2d6;
	color: #fff;
}

.btn-custom-1:hover,
.btn-custom-1:focus,
.btn-custom-1:active,
.btn-custom-1.active {
	background: #fff;
	border-color: #fff;
	color: #fff;
}

.btn-custom-2 {
	background: #4e93e6;
	border-radius: 3px;
	color: #fff;
}

.btn-custom-2:hover,
.btn-custom-2:focus,
.btn-custom-2:active,
.btn-custom-2.active {
	background: #4e93e6;
	color: #fff;
	box-shadow: 0px 10px 20px rgba(0,0,0,0.1) 
}

.btn-custom-3 {
	background: none;
	border: 1px solid #fff;
	color: #fff
}

.btn-custom-3:hover,
.btn-custom-3:focus,
.btn-custom-3:active,
.btn-custom-3.active {
	background: #49e2d6;
	border-color: #49e2d6;
	color: #FFF;
}

.btn-custom-4 {
	background: none;
	border: 1px solid #fff;
	color: #fff;
}

.btn-custom-4:hover,
.btn-custom-4:focus,
.btn-custom-4:active,
.btn-custom-4.active {
	background: #fff;
	color: #FFF;
}

/* Button size */

.btn-lg,
.btn-group-lg > .btn {
	padding: 10px 25px;
	font-size: 13px;
}

.btn-sm,
.btn-group-sm > .btn {
	padding: 6px 25px;
	font-size: 11px;
}

.btn-xs,
.btn-group-xs > .btn {
	padding: 5px 25px;
	font-size: 10px;
}

.btn .icon-before {
	margin-right: 6px;
}

.btn .icon-after {
	margin-left: 6px;
}


.custom-btn {
	display: block;
	font: 400 14px/1.2 'Poppins', sans-serif;
	text-transform: uppercase;
	text-decoration: none;
	letter-spacing: 1px;
	padding: 7px 20px;
	margin: 5px;
	cursor: pointer;
	-webkit-transition: all 0.4s ease-in-out 0s;
	-moz-transition: all 0.4s ease-in-out 0s;
	-ms-transition: all 0.4s ease-in-out 0s;
	-o-transition: all 0.4s ease-in-out 0s;
	transition: all 0.4s ease-in-out 0s;
	background: transparent;
	color: #fff;
	max-width: 200px;
	margin: 15px auto;
	z-index: 22;
	text-align: center;
	border-radius: 0px;
	width: 100%;
	border: none;
	position: relative;
}

.custom-btn:after {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 10px;
	width: 50%;
	background: #4e93e6;
	z-index: -1;
	-webkit-transition: all 0.2s ease-in-out 0s;
	-moz-transition: all 0.2s ease-in-out 0s;
	-ms-transition: all 0.2s ease-in-out 0s;
	-o-transition: all 0.2s ease-in-out 0s;
	transition: all 0.2s ease-in-out 0s;
	margin: 0 auto;
	height: 100%;
	width: 100%;
}

.custom-btn:hover {
	box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
	color: #fff;
}

.custom-btn:hover:after {
	opacity: 0.7;
}
