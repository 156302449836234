
/* ------------------------------------------------------------------- 
 * ## skillbars - (_skillbars.scss)
 * ------------------------------------------------------------------- */

 .skill-bars {
    list-style: none;
    margin-top: 6rem;
}

.skill-bars li {
    height: .6rem;
    background: #FFF;
    width: 100%;
    margin-bottom: 6.9rem;
    padding: 0;
    position: relative;
}

.skill-bars li strong {
    position: absolute;
    left: 0;
    top: -3rem;
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: .2rem;
}

.skill-bars li .progress {
    background-image: linear-gradient(to right, #FFF, theme('colors.primary.400'));
    position: relative;
    height: 100%;
}

.skill-bars li .progress span {
    position: absolute;
    right: 0;
    top: -3.6rem;
    display: block;
    color: #000;
    font-size: 1.1rem;
    line-height: 1;
    background: #FFF;
    padding: .6rem .6rem;
    border-radius: 3px;
}

.skill-bars li .progress span::after {
    position: absolute;
    left: 50%;
    bottom: -5px;
    margin-left: -5px;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-top: 5px solid #FFF;
    content: "";
}

.skill-bars li .percent5 {
    width: 5%;
}

.skill-bars li .percent10 {
    width: 10%;
}

.skill-bars li .percent15 {
    width: 15%;
}

.skill-bars li .percent20 {
    width: 20%;
}

.skill-bars li .percent25 {
    width: 25%;
}

.skill-bars li .percent30 {
    width: 30%;
}

.skill-bars li .percent35 {
    width: 35%;
}

.skill-bars li .percent40 {
    width: 40%;
}

.skill-bars li .percent45 {
    width: 45%;
}

.skill-bars li .percent50 {
    width: 50%;
}

.skill-bars li .percent55 {
    width: 55%;
}

.skill-bars li .percent60 {
    width: 60%;
}

.skill-bars li .percent65 {
    width: 65%;
}

.skill-bars li .percent70 {
    width: 70%;
}

.skill-bars li .percent75 {
    width: 75%;
}

.skill-bars li .percent80 {
    width: 80%;
}

.skill-bars li .percent85 {
    width: 85%;
}

.skill-bars li .percent90 {
    width: 90%;
}

.skill-bars li .percent95 {
    width: 95%;
}

.skill-bars li .percent100 {
    width: 100%;
}
